import React, { type ComponentType } from 'react';
import Script from 'next/script';
import { useMarket } from 'bb/app/useMarket';
import { type Market } from 'bb/config';
import { useHasFeature } from 'bb/config/features';
import { type Feature } from 'bb/config/features/types';
import {
    type UseKindkyChatReturnType,
    useKindlyChat,
    type UseKindlyChatArgs
} from './useKindlyChat';

/**
 * The bot key used to be a single environment variable. But now
 * we are moving towards having multiple bots, one for each market.
 * In the future it is likely more markets will have their own key
 * as well. The environment variable is public, so there's no risk
 * in exposing it.
 */
const BOT_KEY_BY_MARKET: Partial<Record<Market, string>> = {
    se: 'ea51b5d2-3faa-466b-8eec-5f31c03b3b9b',
    fi: '1fa9314c-bb35-47a0-9aa3-b47fe923f651',
    pl: 'c65cd0aa-b483-4366-9182-92270e410ccb',
    // DACH all have the same bot key
    de: '79a24efe-8521-4c3e-be4c-d00726360fcd',
    at: '79a24efe-8521-4c3e-be4c-d00726360fcd',
    ch: '79a24efe-8521-4c3e-be4c-d00726360fcd'
};

export type KindlyChatWidgetProps = {
    /**
     * Render conditionally depending on feature flag.
     */
    feature?: Feature;
    intersectionRef: UseKindlyChatArgs[0];
    path: UseKindlyChatArgs[1];
    cleanupComponent: ComponentType<UseKindkyChatReturnType>;
};
export const KindlyChatWidget = ({
    feature,
    intersectionRef,
    path,
    cleanupComponent: CleanupComponent
}: KindlyChatWidgetProps) => {
    const hasFeature = useHasFeature();
    const { market } = useMarket();

    const kindlyChat = useKindlyChat(intersectionRef, path);

    const botKey = BOT_KEY_BY_MARKET[market];

    /**
     * Prettier goes nuts if we do a ternary return
     * for some reason. This seems to work fine.
     *
     * The z-index is set to 99 as Kindly concats a 5 to whatever we
     * send in meaning 99 will give z-index 995. And we want it to be
     * lower than the modal z-index which is 1000.
     */
    if (botKey && (feature ? hasFeature(feature) : true)) {
        return (
            <>
                <Script
                    crossOrigin="anonymous"
                    id="kindly-chat"
                    src="https://chat.kindlycdn.com/kindly-chat.js"
                    data-bot-key={botKey}
                    data-shadow-dom
                    defer
                    strategy="lazyOnload"
                    data-z-index={99}
                />
                <CleanupComponent {...kindlyChat} />
            </>
        );
    }

    return null;
};
