import { useUser } from 'bb/account/hooks/useUser';
import { useMutation } from 'bb/api/browser';
import { type NextApiPaths } from 'bb/api/types';

export type LogPayload = NonNullable<NextApiPaths['/api/log']['post']['body']>;

/**
 * Unlike `useSimpleLogger` which is actually really complex, this is
 * a simple logger that doesn't rely on a `mobx` store where the actual
 * api call is run as an effect of its state in the `Logger.tsx` component.
 */
export const useLogger = () => {
    const { user } = useUser();
    const { trigger } = useMutation('post', '/api/log');

    const makeLevelFn = (level: LogPayload['level']) => {
        return (
            message: LogPayload['message'],
            payload?: Record<string, unknown>
        ) => {
            return trigger({
                level,
                message,
                userAgent: navigator.userAgent,
                loggedAtUrl: window.location.href,
                clientSide: true,
                userId: user?.userid,
                ...payload
            });
        };
    };

    return {
        info: makeLevelFn('info'),
        error: makeLevelFn('error')
    };
};
