import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useLogger } from 'bb/common/logger/useLogger';

export const LoggingErrorBoundary: React.FC<{
    children?: React.ReactNode;
    errorName: string;
}> = ({ children, errorName }) => {
    const logger = useLogger();
    const onError = (error: Error) => {
        const message = `${errorName} ${error?.name} ${error?.message} ${error?.stack}`;
        logger.error(message);
    };
    return (
        <ErrorBoundary onError={onError} fallback={null}>
            {children}
        </ErrorBoundary>
    );
};
