import React from 'react';
import classnames from 'classnames';
import { Box } from 'bb/ui/Box';
import css from './maxWidthContainer.module.scss';

export type MaxWidthContainerProps<T extends React.ComponentType = typeof Box> =
    {
        maxWidth?: number;
        className?: string;
        children?: React.ReactNode;
        withBackground?: boolean;
        center?: boolean;
        fullWidthInResponsive?: boolean;
        /**
         * The component composes <Box> by default
         * but we can compose any component.
         */
        composes?: T;
        grow?: boolean;
    } & React.ComponentProps<T>;

export const MaxWidthContainer = <T extends React.ComponentType = typeof Box>(
    props: MaxWidthContainerProps<T>
) => {
    const {
        maxWidth = 1600,
        className,
        children,
        withBackground,
        center = true,
        fullWidthInResponsive = false,
        composes: Component = Box,
        grow = false,
        ...restProps
    } = props;

    return (
        <Component
            className={classnames(
                center && css.center,
                withBackground && css.withBackground,
                fullWidthInResponsive && css.fullWidthInResponsive,
                grow && css.grow,
                className
            )}
            style={{ maxWidth }}
            {...restProps}
        >
            {children}
        </Component>
    );
};
