// @ts-strict-ignore
import React, { type CSSProperties, type FC } from 'react';
import classNames from 'classnames';
import css from 'bb/style/common.module.scss';
import { type MarginProps, type PaddingProps, type Breakpoint } from 'bb/ui';
import { Box } from 'bb/ui/Box';

type PagePaddingBoxProps = {
    className?: string;
    pagePadding?: boolean;
    pagePaddingTo?: Breakpoint; // Will have pagePadding styling up to this breakpoint
    style?: CSSProperties;
    role?: string;
    children?: React.ReactNode;
} & PaddingProps &
    MarginProps;

const mappedBreakpointToClassName = {
    xs: 'pagePaddingToXs',
    sm: 'pagePaddingToSm',
    md: 'pagePaddingToMd',
    lg: 'pagePaddingToLg',
    xl: 'pagePaddingToXl',
    xxl: 'pagePaddingToXxl'
};

export const PagePaddingBox: FC<PagePaddingBoxProps> = ({
    className,
    children,
    pagePadding,
    pagePaddingTo,
    ...rest
}) => {
    const paddingToClassName = mappedBreakpointToClassName[pagePaddingTo];

    return (
        <Box
            className={classNames(
                className,
                pagePadding && css.pagePadding,
                paddingToClassName && css[paddingToClassName]
            )}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rest}
        >
            {children}
        </Box>
    );
};
