/* eslint-disable @next/next/no-img-element */
import React, { type ComponentProps } from 'react';
import { useApp, usePaymentMethods } from 'bb/app';
import { useDeviceInfo } from 'bb/common/hooks/useDeviceInfo';
import { getMarketConfig } from 'bb/config/marketConfig';
import { type SupportedPaymentMethodsType } from 'bb/config/paymentMethods';
import {
    type CardType,
    getCCImages,
    type CCImagesItemProps
} from 'bb/payment/CCImages';

export type PaymentMethodsConfig = Record<
    Extract<
        SupportedPaymentMethodsType,
        | 'scheme'
        | 'paypal'
        | 'klarna_paynow'
        | 'applepay'
        | 'googlepay'
        | 'ideal'
    >,
    React.ReactNode[]
>;

export type UsePaymentProviderImagesProps = Partial<
    {
        scheme: Partial<CCImagesItemProps>;
    } & Record<
        keyof Exclude<PaymentMethodsConfig, 'scheme'>,
        Partial<ComponentProps<'img'>>
    >
>;

export const paymentMethodsConfig = (
    additionalCreditCards: string[] = [],
    paymentMethodsPassableProps: UsePaymentProviderImagesProps = {}
) => {
    const config = {
        scheme: getCCImages(
            ['mastercard', 'visa', ...(additionalCreditCards as CardType[])],
            paymentMethodsPassableProps.scheme
        ),
        paypal: [
            <img
                key="paypal"
                alt="paypal logo"
                src="/images/cc/paypal.png"
                loading="lazy"
                {...paymentMethodsPassableProps.paypal}
            />
        ],
        klarna_paynow: [
            <img
                key="klarna_paynow"
                alt="klarna logo"
                src="/images/cc/klarna.png"
                loading="lazy"
                {...paymentMethodsPassableProps.klarna_paynow}
            />
        ],
        applepay: [
            <img
                key="applepay"
                alt="Apple pay logo"
                src="/images/cc/ApplePayIcon.png"
                loading="lazy"
                {...paymentMethodsPassableProps.applepay}
            />
        ],
        googlepay: [
            <img
                key="googlepay"
                alt="Google pay logo"
                src="/images/cc/google_pay.png"
                loading="lazy"
                {...paymentMethodsPassableProps.googlepay}
            />
        ],
        ideal: [
            <img
                key="ideal"
                alt="Ideal logo"
                src="/images/cc/ideal.png"
                loading="lazy"
                {...paymentMethodsPassableProps.ideal}
            />
        ]
    } as const satisfies PaymentMethodsConfig;

    return config;
};

/**
 * This hook tries to unify the way we display payment provider images.
 *
 * It will return an array of tuples where the first element is the payment
 * provider type and the second element is an array of React elements. They
 * can then be directly manipulated or rendered directly in the UI.
 */
export const usePaymentProviderImages = (
    paymentMethodsPassableProps: UsePaymentProviderImagesProps = {}
) => {
    const { market } = useApp();
    const { additionalCreditCards } = getMarketConfig(market) ?? {};
    const { paymentMethods = [], ...restSWRProps } = usePaymentMethods();
    const { os, isMobile } = useDeviceInfo();

    const config = paymentMethodsConfig(
        additionalCreditCards,
        paymentMethodsPassableProps
    );

    const isSupportedMap: Partial<Record<keyof typeof config, () => boolean>> =
        {
            applepay: () => {
                const hasApplePayAPI =
                    typeof window !== 'undefined' && window.ApplePaySession;

                return Boolean(
                    (!isMobile && os.safari && hasApplePayAPI) ||
                        (isMobile && os.iOS)
                );
            }
        };

    const sortedPaymentMethods = paymentMethods
        .filter((method) => {
            return (
                isSupportedMap[method.type as keyof typeof config]?.() ?? true
            );
        })
        .sort((a, b) => {
            if (a.type === 'scheme') return -1;
            if (b.type === 'scheme') return 1;

            return 0;
        });

    return {
        ...restSWRProps,
        paymentMethods: sortedPaymentMethods.map(({ type }) => {
            const elements = config[type as keyof PaymentMethodsConfig] ?? [];

            return [type as keyof PaymentMethodsConfig, elements] as const;
        })
    };
};
