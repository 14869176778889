import { useEffect } from 'react';
import { useRouter } from 'bb/app/router';
import { type UseKindkyChatReturnType } from 'bb/common/KindlyChatWidget/useKindlyChat';

export type CleanupComponentPagesRouterProps = UseKindkyChatReturnType;

export const CleanupComponentPagesRouter = ({
    resetIsIntersecting
}: CleanupComponentPagesRouterProps) => {
    const { router } = useRouter();
    /**
     * Sets setIsIntersecting to false to ensure that the bubble is shown again if
     * the intersectionRef is visable directly on the new page.
     */
    useEffect(() => {
        router.events.on('routeChangeStart', resetIsIntersecting);

        return () => {
            router.events.off('routeChangeStart', resetIsIntersecting);
        };
    }, [router, resetIsIntersecting]);

    return null;
};
